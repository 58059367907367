<template>
	<div id="app" v-if="token">
		<keep-alive v-if="$route.meta.keepAlive">
			<router-view ref="routerView" />
		</keep-alive>
		<router-view ref="routerView" v-else />
	</div>
	<div class="logo_box" v-else>
		<img src="./assets/loading_icon.png" alt="" />
		<van-button class="connect_btn" @click="login" :loading="connectLoading" loading-text="钱包连接中...">连接钱包</van-button>
	</div>

	<!-- <div class="upgrade_box">
		<div class="upgrade_info">
			<div>系統維護中，暫時關閉註冊</div>
			<div>System maintenance, temporarily close registration</div>
		</div>
	</div> -->
</template>
<script>
export default {
	name: "App",
	data() {
		return {
			token: localStorage.getItem("token"),
			userAccount: localStorage.getItem("userAccount"),
			connectLoading: false,
			showBindSuperior: false,
		};
	},
	async mounted() {
		await this.getStore();
		this.$store.state.token = localStorage.getItem("token") || "";
		this.$store.state.user_id = localStorage.getItem("user_id") || "";
		if(this.$route.query.superior){
			localStorage.setItem("inviteAddress", this.$route.query.superior);
		}

		const stopAutoLogin = localStorage.getItem("stopAutoLogin");
		if(!this.token && !stopAutoLogin){
			localStorage.removeItem("stopAutoLogin");
			this.login();
		}

		//获取语言设置
		const language = localStorage.getItem("language") || "zh-hant";
		console.log(language);
		if(language){
			// 切换语言
			this.$i18n.locale = language;
			this.$store.state.language = language;
		}
	},
	methods: {
		async getStore() {
			this.$toast.loading();
			try {
				const config = await this.$api.postData("Config", {});
				const local_version = localStorage.getItem("version");
				const this_version = config.version;
				if(local_version!=null && local_version !== this_version){
					console.log("版本更新，重新加载");
					localStorage.clear();
					location.reload();
					return;
				}
				this.$store.state.config = config;
				localStorage.setItem("config", JSON.stringify(config));
				localStorage.setItem("version", this_version);
			} catch (error) {
				console.log(error);
			} finally {
				this.$toast.clear();
			}
		},
		async login() {
			try {
				this.connectLoading = true;
				const account = await this.$connectWallet(this.$store.state.config);
				if (account) {
					console.log("链接钱包成功：", account);
					this.userAccount = account;
					this.token = localStorage.getItem("token");
				}
			} catch (error) {
				this.$notify({
					type: "danger",
					message: '连接钱包失败，请稍后重试！',
				})
				console.log("链接钱包失败：", error);
			} finally {
				this.connectLoading = false;
			}
		},
	},
};
</script>
<style scoped lang="scss">
.upgrade_box{
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background: rgba(0,0,0,0.5);
	display: flex;
	.upgrade_info{
		width: 80%;
		height: 300px;
		border-radius: 10px;
		background: #fff;
		margin: auto;
		padding: 20px;
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
		justify-content: center;
		gap: 20px;
	}
}
.logo_box{
	background: linear-gradient(180deg, #017eff 0%, #0057d8 100%);
	background-size: 100% 100%;
	background-position: left top;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	position: relative;
	> img {
		margin-top: -150px;
		width: calc(100% - 70px);
	}
	> div {
		font-family: PingFang-SC-Bold;
		font-size: 30px;
		color: #000000;
	}

	.connect_btn{
		position: absolute;
		bottom: 100px;
		width: 85%;
		height: 72px;
		background-color: #fff;
		border-radius: 15px;
		font-family: PingFang-SC-Bold;
		font-size: 20px;
		color: #017eff;
	}
}
</style>
<style lang="scss">
.my_tabs>.van-tabs__wrap{
	display: none !important;
}
html{
	background: #f2f2f2;
	max-width: 500px;
    margin: auto;
}

.none {
	display: flex;
	flex-direction: column;
	height: fit-content;
	justify-content: center;
	align-items: center;
	user-select: none;
	color: #999;
	position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
	transition: 200ms;
	img {
		width: 120px;
		margin-bottom: 10px;
	}
}

.van-notify{
	z-index: 99999 !important;
}
.van-tabbar--fixed{
	max-width: 500px;
    margin: auto;
    left: 0;
    right: 0;
}
</style>>