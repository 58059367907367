import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		loading: null,
		changLoign:0,
		changeSuperior:0,
		config:{},
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
	}
})
