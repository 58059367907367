import {
    Loading,
    Toast,
    Icon,
    Checkbox,
    Dialog,
    Button,
    Form,
    Field,
    Skeleton,
    Uploader,
    Image as VanImage,
    Pagination,
    DropdownMenu,
    DropdownItem,
    Picker,
    Overlay,
    Tab,
    Tabs,
    Sticky,
    ActionSheet,
    Popup,
    PullRefresh,
    List,
    Tabbar,
    TabbarItem,
    NumberKeyboard,
    CountDown,
    Search,
    Swipe, SwipeItem,
    Card,
    Tag,
    Image,
    Lazyload,
    Sku,
    AddressList,
    AddressEdit,
    Area,
    GoodsAction, GoodsActionIcon, GoodsActionButton,
    NoticeBar,
    ContactEdit,
    Circle,
    Col, Row
} from 'vant';

const Vant = {
    install(Vue) {
        Vue.use(Loading);
        Vue.use(Toast);
        Vue.use(Icon);
        Vue.use(Checkbox);
        Vue.use(Dialog);
        Vue.use(Button);
        Vue.use(Form);
        Vue.use(Field);
        Vue.use(Skeleton);
        Vue.use(Uploader);
        Vue.use(VanImage);
        Vue.use(Pagination);
        Vue.use(DropdownMenu);
        Vue.use(DropdownItem);
        Vue.use(Picker);
        Vue.use(Overlay);
        Vue.use(Tab);
        Vue.use(Tabs);
        Vue.use(Sticky);
        Vue.use(ActionSheet);
        Vue.use(Popup);
        Vue.use(PullRefresh);
        Vue.use(List);
        Vue.use(Tabbar);
        Vue.use(TabbarItem);
        Vue.use(NumberKeyboard);
        Vue.use(CountDown);
        Vue.use(Search);
        Vue.use(Swipe);
        Vue.use(SwipeItem);
        Vue.use(Card);
        Vue.use(Tag);
        Vue.use(Image);
        Vue.use(Lazyload);
        Vue.use(Sku);
        Vue.use(AddressList);
        Vue.use(AddressEdit);
        Vue.use(Area);
        Vue.use(GoodsAction);
        Vue.use(GoodsActionButton);
        Vue.use(GoodsActionIcon);
        Vue.use(NoticeBar);
        Vue.use(ContactEdit);
        Vue.use(Circle);
        Vue.use(Col);
        Vue.use(Row);
    }
};

export default Vant;