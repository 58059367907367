<template>
	<div class="App">
		<Head @custom-event="ChangeAccount" />
		<van-pull-refresh class="content_refresh" :head-height="50" v-model="refreshLoading" @refresh="onRefresh" :pulling-text="$t('index.t1')" :loosing-text="$t('index.t2')" :loading-text="$t('index.t3')">
			<div class="user_box">
				<div class="info_box" v-if="token">
					<div class="level_box">
						<!-- 我的账户 -->
						<span>{{ $t('index.t4') }}</span>
						<img v-if="userInfo.vip" class="level" :src="`/static/level/level${userInfo.vip}.png`" alt="" />
					</div>
					<div class="address">
						{{ userAccount }}
					</div>
				</div>
			</div>
			<div class="fund_box">
				<img class="record" @click="showMoneyLog" src="../assets/record.png" alt="" />
				<div class="balance">
					<!-- 账户余额 -->
					<div class="label">{{ $t('index.t5') }}(USDT)</div>
					<div class="value">
						<span v-if="userInfo.usdt">{{ $utils.handleAmount(userInfo.usdt, 6, 2) }}</span>
						<span class="value" v-else>--</span>
					</div>
				</div>
				<div class="token_amount_box">
					<div>
						<!-- 代币余额 -->
						<div class="label">{{ $t('index.t6') }}(Pow)</div>
						<div class="value">
							<span v-if="userInfo.pow">{{ $utils.handleAmount(userInfo.pow, 6, 2) }}</span>
							<span class="value" v-else>--</span>
						</div>
					</div>
					<div>
						<!-- 已解锁 -->
						<div class="label">{{ $t('index.t7') }}(Pow)</div>
						<div class="value">
							<span v-if="userInfo.unlock">{{ $utils.handleAmount(userInfo.unlock, 6, 2) }}</span>
							<span class="value" v-else>--</span>
						</div>
					</div>
				</div>
			</div>
			<div class="grid_box">
				<div @click="show_pledge = true">
					<img class="icon" src="../assets/icon1.png" alt="" />
					<!-- 质押转出 -->
					<div>{{ $t('index.t8') }}</div>
				</div>
				<div @click="show_buy = true">
					<img class="icon" src="../assets/icon2.png" alt="" />
					<!-- 购买挂单 -->
					<div>{{ $t('index.t9') }}</div>
				</div>
				<div @click="exchange">
					<img class="icon" src="../assets/icon3.png" alt="" />
					<!-- 闪兑出售 -->
					<div>{{ $t('index.t10') }}</div>
				</div>
				<div @click="toWallet">
					<img class="icon" src="../assets/icon4.png" alt="" />
					<!-- 充值提现 -->
					<div>{{ $t('index.t11') }}</div>
				</div>
			</div>
			<div class="orders_box">
				<van-tabs v-model="order_active" animated style="margin-top: 10px;" sticky offset-top="50px">
					<van-tab :title="$t('index.t12')">
						<div class="order" v-for="(item, index) in orders" :key="index" @click="buy_order_info(item)">
							<div class="title">
								<span>USDT / POW</span>
								<!-- 委托中 -->
								<span class="status">{{ $t('index.t12') }}</span>
							</div>
							<div class="price_time">
								<div>
									<!-- 委托单价： -->
									{{ $t('index.t13') }}
									<span class="price">{{ $utils.handleAmount(item.price, 6, 4) }}</span>
								</div>
								<span>{{ $utils.handleTime(item.create_time) }}</span>
							</div>
							<div class="amount">
								<div>
									<!-- 求购数量 -->
									<div>{{ $t('index.t14') }}(Pow)</div>
									<div>{{ $utils.handleAmount(item.amount, 4, 2) }}</div>
								</div>
								<div>
									<!-- 已成交量 -->
									<div>{{ $t('index.t15') }}(Pow)</div>
									<div>{{ $utils.handleAmount(item.deal_amount, 4, 2) }}</div>
								</div>
								<div>
									<!-- 剩余数量 -->
									<div>{{ $t('index.t16') }}(Pow)</div>
									<div>{{ $utils.handleAmount($utils.toBN(item.amount) - $utils.toBN(item.deal_amount), 4, 2) }}</div>
								</div>
							</div>
						</div>
						<div class="none_box" v-if="orders.length == 0">
							<div class="none" style="bottom: 100px" :style="`opacity:${refreshLoading ? 0 : 1}`">
								<img src="../assets/no_record.png" alt="" />
								<!-- 暂无数据 -->
								<div>{{ $t('index.t17') }}</div>
							</div>
						</div>
					</van-tab>
					<!-- 历史委托 -->
					<van-tab :title="$t('index.t17_3')">
						<div class="order" v-for="(item, index) in orders_history" :key="index" @click="buy_order_info(item)">
							<div class="title">
								<span>USDT / POW</span>
								<!-- 已撤单 完全成交 -->
								<span class="status">{{ item.status == 0 ? $t('index.t17_1') : $t('index.t17_2') }}</span>
							</div>
							<div class="price_time">
								<div>
									<!-- 委托单价： -->
									{{ $t('index.t18') }}
									<span class="price">{{ $utils.handleAmount(item.price, 6, 4) }}</span>
								</div>
								<span>{{ $utils.handleTime(item.create_time) }}</span>
							</div>
							<div class="amount">
								<div>
									<!-- 求购数量 -->
									<div>{{ $t('index.t19') }}(Pow)</div>
									<div>{{ $utils.handleAmount(item.amount, 4, 2) }}</div>
								</div>
								<div>
									<!-- 已成交量 -->
									<div>{{ $t('index.t20') }}(Pow)</div>
									<div>{{ $utils.handleAmount(item.deal_amount, 4, 2) }}</div>
								</div>

								<div v-if="item.status == 2">
									<!-- 价值 -->
									<div>{{ $t('index.t21') }}(USDT)</div>
									<div>{{ $utils.handleAmount(item.total, 4, 2) }}</div>
								</div>
								<div v-else>
									<!-- 未成交 -->
									<div>{{ $t('index.t22') }}(Pow)</div>
									<div>{{ $utils.handleAmount($utils.toBN(item.amount) - $utils.toBN(item.deal_amount), 4, 2) }}</div>
								</div>
							</div>
						</div>
						<div class="none_box" v-if="orders_history.length == 0">
							<div class="none" style="bottom: 100px" :style="`opacity:${refreshLoading ? 0 : 1}`">
								<img src="../assets/no_record.png" alt="" />
								<!-- 暂无数据 -->
								<div>{{ $t('index.t23') }}</div>
							</div>
						</div>
					</van-tab>
					<!-- 出售记录 -->
					<van-tab :title="$t('index.t24_1')">
						<div class="order" v-for="(item, index) in sell_orders" :key="index" @click="sell_order_info(item)">
							<div class="title">
								<span>POW / USDT</span>
								<!-- 闪兑出售 -->
								<span class="status">{{ $t('index.t24') }}</span>
							</div>
							<div class="price_time">
								<div>
									<!-- 成交均价： -->
									{{ $t('index.t25') }}
									<span class="price">{{ $utils.handleAmount(item.price, 6, 4) }}</span>
								</div>
								<span>{{ $utils.handleTime(item.create_time) }}</span>
							</div>
							<div class="amount">
								<div>
									<!-- 出售数量 -->
									<div>{{ $t('index.t26') }}(Pow)</div>
									<div>{{ $utils.handleAmount(item.amount, 4, 2) }}</div>
								</div>
								<div>
									<!-- 收益 -->
									<div>{{ $t('index.t27') }}(USDT)</div>
									<div>{{ $utils.handleAmount(item.total, 4, 2) }}</div>
								</div>
								<div>
									<!-- 手续费 -->
									<div>{{ $t('index.t28') }}(USDT)</div>
									<div>{{ $utils.handleAmount(item.fee, 4, 2) }}</div>
								</div>
							</div>
						</div>
						<div class="none_box" v-if="sell_orders.length == 0">
							<div class="none" style="bottom: 100px" :style="`opacity:${refreshLoading ? 0 : 1}`">
								<img src="../assets/no_record.png" alt="" />
								<!-- 暂无数据 -->
								<div>{{ $t('index.t29') }}</div>
							</div>
						</div>
					</van-tab>
				</van-tabs>
			</div>
		</van-pull-refresh>

		<!-- 质押弹窗 -->
		<van-popup v-model="show_pledge" round position="bottom">
			<div class="popup_content">
				<!-- 转出到质押 -->
				<div class="title">{{ $t('index.t30') }}</div>
				<div class="amount_box">
					<!-- 质押数量 -->
					<van-field readonly v-model="pledge_amount" type="number" :placeholder="$t('index.t30_1')" />
				</div>
				<div class="can_amount">
					<!-- 可质押数量： -->
					<span v-if="userInfo.pow">{{ $t('index.t31') }}{{ $utils.handleAmount(userInfo.pow, 8, 2) }}</span>
					<!-- 全部 -->
					<span class="all" @click="pledge_amount = $utils.fromWei(userInfo.pow)">{{ $t('index.t32') }}</span>
				</div>
				<div class="btn_box">
					<!-- 取消 -->
					<van-button round type="default" @click="show_pledge = false" style="background-color: #e5ecff">{{ $t('index.t33') }}</van-button>
					<!-- 确认质押 -->
					<van-button round type="info" :disabled="pledge_amount <= 0" @click="confirmPledge" :loading="confirmPledgeLoading" :loading-text="$t('index.t34')">{{ $t('index.t35') }}</van-button>
				</div>
				<div class="remark">
					<!-- 转出到质押预计需要5分钟完成 -->
					<div>{{ $t('index.t36') }}</div>
					<!-- 转出成功后，如需查看或兑换算力请 -->
					<!-- 前往PowerLp -->
					<div>{{ $t('index.t37') }}<span @click="toPowerLp">{{ $t('index.t38') }}</span></div>
					<!-- PowerLp中兑换的算力包产生的收益会自动转移到您的账户中 -->
					<div>{{ $t('index.t39') }}</div>
				</div>
				<van-number-keyboard v-model="pledge_amount" extra-key="." :show="true" :hide-on-click-outside="false" />
			</div>
		</van-popup>

		<!-- 求购弹窗 -->
		<van-popup v-model="show_buy" round position="bottom">
			<div class="popup_content no_keyboard">
				<!-- 求购单 -->
				<div class="title">{{ $t('index.t40') }}</div>
				<div class="input_box">
					<!-- 求购数量 -->
					<div class="lable">{{ $t('index.t41') }}（{{ $t('index.t103') }}）</div>
					<div class="input">
						<van-field v-model="buy_amount" type="number" placeholder="0" format-trigger="onBlur" :formatter="formatter" />
						<span>Pow</span>
					</div>
				</div>
				<div class="input_box">
					<!-- 委托单价 -->
					<div class="lable">{{ $t('index.t42') }}（{{ $t('index.t106',{min_sell_price: tokenInfo.min_sell_price}) }}）</div>
					<div class="input">
						<van-field v-model="buy_price" type="number" placeholder="0" format-trigger="onBlur" :formatter="formatter" />
						<span>USDT</span>
					</div>
				</div>
				<!-- <div>
					<span>支付金额：{{ buy_amount * buy_price }}</span>
				</div> -->
				<div class="input_box">
					<!-- 支付金额 -->
					<div class="lable">{{ $t('index.t43') }}</div>
					<div class="input" style="background: #f0f0f0">
						<div>{{ $utils.numToK(buy_amount * buy_price, 4, 2) }}</div>
						<!-- <van-field readonly :value="buy_amount * buy_price" type="number" placeholder="0" /> -->
						<span>USDT</span>
					</div>
					<div class="can_amount" v-if="userInfo.usdt">
						<!-- 账户余额： -->
						<span>{{ $t('index.t44') }}{{ $utils.handleAmount(userInfo.usdt, 8, 2) }}</span>
					</div>
				</div>

				<div class="btn_box">
					<!-- 取消 -->
					<van-button round type="default" @click="show_buy = false" style="background-color: #e5ecff">{{ $t('index.t45') }}</van-button>
					<!-- 下单中... -->
					<!-- 确认 -->
					<van-button round type="info" @click="createOrder" 
					:loading="createOrderLoading" :loading-text="$t('index.t46')">{{ $t('index.t47') }}</van-button>
				</div>

				<div class="remark">
					<!-- 您的求购订单将按照价格高低和时间先后进行排队成交 -->
					<div>{{ $t('index.t48') }}</div>
					<!-- 每购买成交1个Pow,24小时将为您解锁1.1个Pow可售金额 -->
					<div>{{ $t('index.t49') }}</div>
					<!-- 未成交部分可撤单，将收取撤回订单金额3%手续费的USDT -->
					<div>{{ $t('index.t50') }}</div>
				</div>
			</div>
		</van-popup>

		<!-- 求购单详情 -->
		<van-popup v-model="show_buy_order" round position="bottom" closeable :style="{ maxHeight: '90%' }">
			<div class="popup_content" style="padding-bottom: 40px">
				<!-- 订单详情 -->
				<div class="title" style="line-height: 80px">{{ $t('index.t51') }}</div>
				<div class="order_info">
					<div>
						<!-- 订单编号 -->
						<span>{{ $t('index.t52') }}</span>
						<span>{{ buy_order_item.order_num }}</span>
					</div>
					<div>
						<!-- 订单状态 -->
						<span>{{ $t('index.t53') }}</span>
						<!-- 已撤单 -->
						<span v-if="buy_order_item.status == 0">{{ $t('index.t54') }}</span>
						<!-- 委托中 -->
						<span v-else-if="buy_order_item.status == 1">{{ $t('index.t55') }}</span>
						<!-- 完成交易 -->
						<span v-else-if="buy_order_item.status == 2">{{ $t('index.t56') }}</span>
					</div>
					<div>
						<!-- 求购数量 -->
						<span>{{ $t('index.t57') }}</span>
						<span>{{ $utils.handleAmount(buy_order_item.amount, 4, 2) }} Pow</span>
					</div>
					<div>
						<!-- 委托价格 -->
						<span>{{ $t('index.t58') }}</span>
						<span>{{ $utils.handleAmount(buy_order_item.price, 6, 4) }} USDT</span>
					</div>
					<div>
						<!-- 已成交量 -->
						<span>{{ $t('index.t59') }}</span>
						<span>{{ $utils.handleAmount(buy_order_item.deal_amount, 4, 2) }} Pow</span>
					</div>
					<div>
						<!-- 委托价值 -->
						<span>{{ $t('index.t60') }}</span>
						<span>{{ $utils.handleAmount($utils.fromWei($utils.toBN(buy_order_item.amount) * $utils.toBN(buy_order_item.price), 'ether'), 4, 2) }} USDT</span>
					</div>
					<div>
						<!-- 已成交价值 -->
						<span>{{ $t('index.t61') }}</span>
						<span>{{ $utils.handleAmount($utils.fromWei($utils.toBN(buy_order_item.deal_amount) * $utils.toBN(buy_order_item.price), 'ether'), 4, 2) }} USDT</span>
					</div>
					<div>
						<!-- 解锁可售 -->
						<span>{{ $t('index.t62') }}</span>
						<span>{{ $utils.handleAmount(buy_order_item.deal_amount * 1.1, 4, 2) }} Pow</span>
					</div>
					<div>
						<!-- 剩余价值 -->
						<span>{{ $t('index.t62_1') }}</span>
						<span>{{ $utils.handleAmount((buy_order_item.amount - buy_order_item.deal_amount) * $utils.fromWei(buy_order_item.price), 4, 2) }} USDT</span>
					</div>
					<div class="line"></div>
					<template v-if="buy_order_item.status == 0">
						<div class="gray">
							<!-- 退还金额 -->
							<span>{{ $t('index.t63') }}</span>
							<span>{{ $utils.handleAmount(buy_order_item.cancel_usdt, 4, 2) }} USDT</span>
						</div>
						<div class="gray">
							<!-- 撤单手续费 -->
							<span>{{ $t('index.t64') }}</span>
							<span>{{ $utils.handleAmount(buy_order_item.cancel_fee, 4, 2) }} USDT</span>
						</div>
					</template>
				</div>

				<template v-if="buy_order_view.length > 0">
					<!-- 交易对手 -->
					<div class="order_view_title">{{ $t('index.t65') }}</div>
					<div class="order_view">
						<div class="exchange_log" v-for="(item, index) in buy_order_view" :key="index">
							<div>
								<!-- 成交 -->
								<div>{{ $t('index.t66') }}(Pow)</div>
								<div>{{ $utils.handleAmount(item.amount, 4, 2) }}</div>
							</div>
							<div>
								<!-- 可解锁 -->
								<div>{{ $t('index.t67') }}(USDT)</div>
								<div>{{ $utils.handleAmount(item.unlock_pow, 4, 2) }}</div>
							</div>
							<div>
								<!-- 解锁时间 -->
								<div>{{ $t('index.t68') }}</div>
								<div class="unlock_time">{{ $utils.formatTime(item.unlock_time,"MM/DD HH:mm:ss") }}</div>
							</div>
							<div>
								<!-- 解锁状态 -->
								<div>{{ $t('index.t69') }}</div>
								<!-- 待解锁 -->
								<div v-if='item.unlock_status==0' class="status0">{{ $t('index.t70') }}</div>
								<!-- 已解锁 -->
								<div v-else class="status1">{{ $t('index.t71') }}</div>
							</div>
							<div>
								<!-- 成交时间 -->
								<div>{{ $t('index.t72') }}</div>
								<div class="unlock_time">{{ $utils.formatTime(item.deal_time,"MM/DD HH:mm:ss") }}</div>
							</div>
							<div>
								<!-- 交易对手 -->
								<div>{{ $t('index.t73') }}</div>
								<div>{{ $utils.handleAddress(item.seller, 6, 4) }}</div>
							</div>
						</div>
					</div>
				</template>
				<div class="remark" v-if="buy_order_item.status == 1">
					<!-- 您的求购订单将按照价格高低和时间先后进行排队成交 -->
					<div>{{ $t('index.t74') }}</div>
					<!-- 每购买成交1个Pow,24小时将为您解锁1.1个Pow可售金额 -->
					<div>{{ $t('index.t75') }}</div>
					<!-- 未成交部分可撤单，将收取撤回部分5%手续费的代币 -->
					<div>{{ $t('index.t76') }}</div>
				</div>
				<div class="btn_box">
					<!-- 关闭 -->
					<van-button round type="default" @click="show_buy_order = false" style="background-color: #e5ecff">{{ $t('index.t77') }}</van-button>
					<!-- 撤单中... / 撤单 -->
					<van-button v-if="buy_order_item.status == 1" round type="info" @click="cancelOrder" :loading="cancelOrderLoading" :loading-text="$t('index.t78')">{{ $t('index.t79') }}</van-button>
				</div>
			</div>
		</van-popup>

		<!-- 出售订单详情 -->
		<van-popup v-model="show_sell_order" round position="bottom" closeable :style="{ maxHeight: '90%' }">
			<div class="popup_content" style="padding-bottom: 40px">
				<!-- 订单详情 -->
				<div class="title" style="line-height: 80px">{{ $t('index.t80') }}</div>
				<div class="order_info">
					<div>
						<!-- 订单状态 -->
						<span>{{ $t('index.t81') }}</span>
						<!-- 闪兑成功 -->
						<span>{{ $t('index.t82') }}</span>
					</div>
					<div>
						<!-- 兑换时间 -->
						<span>{{ $t('index.t83') }}</span>
						<span>{{ $utils.handleTime(sell_order_item.create_time) }}</span>
					</div>
					<div>
						<!-- 消耗 -->
						<span>{{ $t('index.t84') }}</span>
						<span>{{ $utils.handleAmount(sell_order_item.amount, 4, 2) }} Pow</span>
					</div>
					<div>
						<!-- 获得 -->
						<span>{{ $t('index.t85') }}</span>
						<span>{{ $utils.handleAmount(sell_order_item.income, 4, 2) }} USDT</span>
					</div>
					<div>
						<!-- 成交均价 -->
						<span>{{ $t('index.t86') }}</span>
						<span>{{ $utils.handleAmount(sell_order_item.price, 4, 2) }} USDT</span>
					</div>
					<div>
						<!-- 手续费 -->
						<span>{{ $t('index.t87') }}</span>
						<span>{{ $utils.handleAmount(sell_order_item.fee, 4, 2) }} USDT</span>
					</div>
				</div>
				<template v-if="sell_order_view.length > 0">
					<!-- 交易对手 -->
					<div class="order_view_title">{{ $t('index.t88') }}</div>
					<div class="order_view">
						<div class="exchange_log" v-for="(item, index) in sell_order_view" :key="index">
							<div>
								<!-- 消耗 -->
								<div>{{ $t('index.t89') }}(Pow)</div>
								<div>{{ $utils.handleAmount(item.amount, 4, 2) }}</div>
							</div>
							<div>
								<!-- 单价 -->
								<div>{{ $t('index.t90') }}(USDT)</div>
								<div>{{ $utils.handleAmount(item.price, 4, 2) }}</div>
							</div>
							<div>
								<!-- 价值 -->
								<div>{{ $t('index.t91') }}(USDT)</div>
								<div>{{ $utils.handleAmount(item.total, 4, 2) }}</div>
							</div>
							<div>
								<!-- 交易对手 -->
								<div>{{ $t('index.t92') }}</div>
								<div>{{ $utils.handleAddress(item.buyer, 2, 4) }}</div>
							</div>
						</div>
					</div>
				</template>
				<div class="btn_box">
					<!-- 关闭 -->
					<van-button round type="default" @click="show_sell_order = false" style="background-color: #e5ecff">{{ $t('index.t93') }}</van-button>
				</div>
			</div>
		</van-popup>
		<van-button style="width:100%;margin-bottom:50px;" type="info" @click="get_test_usdt" :loading="getTestUsdtLoading" loading-text="领取中...">领取测试USDT</van-button>
	</div>
</template>

<script>
import Head from "@/components/Head.vue";
export default {
	components: {
		Head,
	},
	data() {
		return {
			userAccount: "",
			token: localStorage.getItem("token"),
			userInfo: {},
			orders: [],
			orders_history: [],
			sell_orders: [],
			inviteLink: window.location.origin + "/#/?superior=",
			loading: true,
			value: "",
			list: [],
			isTop: false,
			refreshLoading: false,
			isFirst: true,
			connectLoading: false,

			show_pledge: false,
			pledge_amount: "",
			confirmPledgeLoading: false,

			show_buy: false,
			buy_amount: "",
			buy_price: "",
			createOrderLoading: false,

			order_active: 0,
			show_buy_order: false,
			buy_order_item: {},
			cancelOrderLoading: false,
			buy_order_view: [],

			show_sell_order: false,
			sell_order_item: {},
			sell_order_view: [],

			getTestUsdtLoading:false,
			tokenInfo:{
				min_sell_price:0.2,
			}
		};
	},

	beforeRouteLeave(to, from, next) {
		// 当用户离开当前页面时，可以进行数据清理
		this.$destroy(); // 销毁组件实例
		next();
	},
	activated() {
		this.token = localStorage.getItem("token");
		if (!this.token) {
			this.goLogin();
			return;
		}

		if (this.isFirst) {
			return;
		}
		this.userAccount = localStorage.getItem("userAccount");
		this.getData();
	},
	mounted() {
		console.log("mounted");
		this.token = localStorage.getItem("token");
		if (!this.token) {
			this.goLogin();
			return;
		}

		this.userAccount = localStorage.getItem("userAccount");
		this.getData();
	},
	watch: {
		//changeSuperior
		"$store.state.changeSuperior": function (newVal, oldVal) {
			this.getData();
		},
		"buy_amount": function(newVal, oldVal){
			if(newVal>500){
				this.buy_amount = 500
			}
		}
	},
	methods: {
		ChangeAccount() {
			console.log("ChangeAccount");
		},
		async onRefresh() {
			if (this.token) {
				this.getData();
			} else {
				this.refreshLoading = false;
			}
		},
		async getData() {
			try {
				const res = await this.$api.postData("getUser", {
					getOrder: 1,
				});
				if (res.code == 200) {
					this.userInfo = res.data.user;
					this.tokenInfo = res.data.token;
					this.orders = res.data.orders;
					this.orders_history = res.data.orders_history;
					this.sell_orders = res.data.sell_orders;
					this.$store.state.userInfo = res.data;
					localStorage.setItem("userInfo", JSON.stringify(res.data));
					if (!res.data.parent_address) {
						this.$emit("bindParent");
					}
				} else {
					this.$notify({
						type: "danger",
						message: res.msg,
					});
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.isFirst = false;
				this.refreshLoading = false;
				this.$toast.clear();
			}
		},
		goOrder() {
			this.$router.push({
				path: "/order",
			});
		},
		logout() {
			if (!this.token) return;
			//询问
			this.$dialog
				.confirm({
					title: this.$t('index.t94'),	//提示
					message: this.$t('index.t95'),	//是否退出登录？
				})
				.then(() => {
					this.goLogin();
				})
				.catch(() => {
					console.log("取消");
				});
		},
		copyLink() {
			this.copy(this.inviteLink + this.userAccount);
		},
		copy(str) {
			this.$copyText(str).then(
				() => {
					this.$notify({
						type: "success",
						message: this.$t('index.t96'), //"复制成功"
					});
				},
				() => {
					this.$notify({
						type: "danger",
						message: this.$t('index.t97'), //"复制失败"
					});
				}
			);
		},
		goLogin() {
			localStorage.clear();
			window.location.reload();
		},
		showIntegral() {
			this.$router.push({
				path: "/integral",
			});
		},
		showTeam() {
			this.$router.push({
				path: "/team",
			});
		},
		showMoneyLog() {
			this.$router.push({
				path: "/balance",
			});
		},
		showAddress() {
			this.$router.push({
				path: "/address",
			});
		},
		async toUserInfo() {
			this.$router.push({
				path: "/userInfo",
			});
		},
		toWallet() {
			this.$router.push({
				path: "/wallet",
			});
		},
		exchange() {
			this.$router.push({
				path: "/exchange",
			});
		},
		toPowerLp() {
			// window.open("https://dapp.powerlp.online/");	//正式
			window.open("https://tapp.powerlp.online/#/");	//測試
		},
		formatter(val) {
			if (!val) {
				return "";
			}
			return this.$utils.numToK(val, 4, 0);
		},
		async createOrder() {
			//验证数量和单价
			if (this.buy_amount <= 0) {
				this.$notify({ type: "danger", message: this.$t('index.t97') });	//"请输入求购数量"
				return;
			}
			if (this.buy_price <= 0) {
				this.$notify({ type: "danger", message: this.$t('index.t99') });	//"请输入求购单价"
				return;
			}
			if (this.buy_amount > 500){
				this.$notify({ type: "danger", message: this.$t('index.t103') });	//"请输入求购数量"
				return;
			}
			if(this.buy_price < this.tokenInfo.min_sell_price){
				this.$notify({ type: "danger", message: this.$t('index.t105',{min_sell_price:this.tokenInfo.min_sell_price}) });	//"请输入求购数量"
				return;
			}
			try {
				this.createOrderLoading = true;
				//查询地址是否存在
				const res = await this.$api.postData("/createOrder", {
					amount: this.$utils.toWei(this.buy_amount),
					price: this.$utils.toWei(this.buy_price),
				});

				if (res.code == 200 || res.code == 201) {
					this.$notify({ type: "success", message: res.msg });
					this.show_buy = false;
					this.buy_amount = "";
					this.buy_price = "";
					this.getData();
				} else {
					if(res.tcode){
						this.$notify({ type: "danger", message: this.$t(res.tcode) });
					}else{
						this.$notify({ type: "danger", message: res.msg });
					}
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.createOrderLoading = false;
			}
		},
		async buy_order_info(order_item) {
			this.buy_order_item = order_item;
			console.log(order_item);
			//查询交易对手
			try {
				this.$toast.loading();
				//查询地址是否存在
				const res = await this.$api.postData("/buyOrderView", {
					order_id: order_item.id,
				});

				if (res.code == 200) {
					this.buy_order_view = res.data;
					this.show_buy_order = true;
				} else{
					this.$notify({ type: "danger", message: res.msg });
				}
			} catch (error) {
				console.log(error);
				this.$notify({ type: "danger", message: this.$t('index.t100') });	//'网络错误'
			} finally {
				this.$toast.clear();
			}
		},
		async sell_order_info(order_item) {
			console.log(order_item);
			this.sell_order_item = order_item;
			
			//查询交易对手
			try {
				this.$toast.loading();
				//查询地址是否存在
				const res = await this.$api.postData("/sellOrderView", {
					exchange_id: order_item.id,
				});

				if (res.code == 200) {
					this.sell_order_view = res.data;
					this.show_sell_order = true;
				} else{
					this.$notify({ type: "danger", message: res.msg });
				}
			} catch (error) {
				console.log(error);
				this.$notify({ type: "danger", message: this.$t('index.t100') });	//'网络错误'
			} finally {
				this.$toast.clear();
			}
		},
		//撤单
		async cancelOrder() {
			try {
				this.cancelOrderLoading = true;
				//查询地址是否存在
				const res = await this.$api.postData("/cancelOrder", {
					order_id: this.buy_order_item.id,
					order_num: this.buy_order_item.order_num,
				});

				if (res.code == 200 || res.code == 201) {
					this.$notify({ type: "success", message: res.msg });
					this.show_buy_order = false;
					this.getData();
				} else {
					this.$notify({ type: "danger", message: res.msg });
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.cancelOrderLoading = false;
			}
		},
		//转出质押
		async confirmPledge() {
			const pledge_amount = Number(this.pledge_amount);
			//验证数量
			if (pledge_amount <= 0) {
				this.$notify({ type: "danger", message: this.$t('index.t101') });	//请输入转出数量
				return;
			}
			if (pledge_amount > Number(this.$utils.fromWei(this.userInfo.pow))) {
				this.$notify({ type: "danger", message: this.$t('index.t102') });	//您的代币余额不足
				return;
			}
			// if (pledge_amount > Number(this.$utils.fromWei(this.userInfo.unlock))) {
			// 	this.$notify({ type: "danger", message: this.$t('index.t104') });	//解锁额度不足
			// 	return;
			// }
			try {
				this.confirmPledgeLoading = true;
				//查询地址是否存在
				const res = await this.$api.postData("/confirmPledge", {
					amount: this.$utils.toWei(pledge_amount),
				});
				if (res.code == 200) {
					this.$notify({ type: "success", message: res.msg });
					this.getData();

					this.pledge_amount = "";
					this.show_pledge = false;
				} else {
					if(res.tcode){
						this.$notify({ type: "danger", message: this.$t(res.tcode) });
					}else{
						this.$notify({ type: "danger", message: res.msg });
					}
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.confirmPledgeLoading = false;
			}
		},
		async get_test_usdt(){
			try {
				this.getTestUsdtLoading = true;
				await new this.$MyContract().send("usdt", "mint", []);
			} catch (error) {
				console.log(error);
			} finally {
				this.getTestUsdtLoading = false;
			}
		}
	},
};
</script>
<style>
.van-loading__text,
.van-pull-refresh__head {
	color: #f8f8f8 !important;
}
.van-tabs--line .van-tabs__wrap {
	border-radius: 10px 10px 0 0;
}
.van-tabs__line {
	bottom: 20px !important;
}
</style>
<style scoped lang="scss">
.App {
	padding: 0 15px;
	position: relative;
	overflow: hidden auto;
	height: 100vh;
	background: url(../assets/top_bj.png) no-repeat;
	background-size: 100% auto;
	background-position-x: center;
	background-position-y: 50px;
	.content_refresh {
		padding-top: 50px;
		// height: calc(100vh - 50px);

		min-height: calc(100vh - 70px);
		padding-bottom: 20px;
	}
}

.logo_box {
	background: url(../assets/login_bj.jpg) no-repeat;
	background-size: 100% 100%;
	background-position: left top;
}
.user_box {
	background: url(../assets/account_bj.png) no-repeat;
	background-size: 100% 100%;
	height: 109px;
	border-radius: 10px;

	.info_box {
		padding: 20px 20px 0;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		.level_box {
			display: flex;
			align-items: center;
			.level {
				width: 50px;
				margin-left: 11px;
			}
		}

		.address {
			margin-top: 10px;
			font-family: PingFang-SC-Medium;
			font-size: 12px;
			color: #000000;
		}
	}
}
.fund_box {
	margin-top: -25px;
	background: url(../assets/fund_bj.png) no-repeat;
	background-size: 100% 100%;
	padding: 0 15px 15px;
	height: 208px;

	text-align: center;
	color: #fff;
	line-height: 1;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	.record {
		position: absolute;
		right: 10px;
		top: 10px;
		margin: auto;
		width: 18px;
		background: #fff;
		padding: 2px;
		border-radius: 5px;
	}
	.balance {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 20px;
		.label {
			font-family: PingFang-SC-Medium;
			font-size: 14px;
			color: #e5ecff;
		}
		.value {
			font-family: PingFang-SC-Bold;
			font-size: 24px;
			color: #e5ecff;
		}
	}
	> .token_amount_box {
		display: flex;
		gap: 10px;
		> div {
			padding: 25px 10px;
			background-color: #409fff;
			border-radius: 8px;
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 20px;

			.label {
				font-family: PingFang-SC-Medium;
				font-size: 12px;
				color: #ffffff;
			}
			.value {
				font-family: PingFang-SC-Bold;
				font-size: 18px;
				color: #ffffff;
			}
		}
	}
}
.grid_box {
	margin-top: 10px;
	background: #fff;
	padding: 20px 0;
	display: flex;
	justify-content: space-around;
	font-size: 12px;
	text-align: center;
	color: #333;
	border-radius: 10px;
	img {
		width: 60px;
		margin-bottom: 4px;
	}
}

.spec {
	margin-top: 10px;
	border-radius: 10px;
	background: #fff;
	padding: 0 15px;
	font-size: 14px;
	line-height: 1.5;
	color: #333;
	> div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 54px;
		> div:first-child {
			display: flex;
			align-items: center;
			gap: 10px;
		}
		> div:last-child {
			color: #999;
		}
		transition: 200ms;
	}

	.not_count {
		color: #ff9800;
		font-size: 13px;
	}
	.icon {
		width: 18px;
	}
}

.logout {
	margin-top: 10px;
	height: 54px;
	background-color: #ffffff;
	border-radius: 10px;
	text-align: center;
	line-height: 54px;
	font-size: 16px;
	color: #ff0000;
}

.popup_content {
	text-align: center;
	padding: 0 15px 40px;
	padding-bottom: 275px;
	.title {
		font-family: PingFang-SC-Bold;
		font-size: 18px;
		color: #000000;
		line-height: 90px;
	}
	.amount_box .van-field {
		padding: 0 15px;
		border-radius: 10px;
		border: solid 1px #cccccc;
		height: 56px;
		line-height: 56px;
	}
	.can_amount {
		margin-top: 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-family: PingFang-SC-Medium;
		font-size: 12px;
		color: #000000;
		.all {
			color: #017eff;
		}
	}
	.btn_box {
		margin-top: 36px;
		display: flex;
		justify-content: space-between;
		gap: 10px;
		> button {
			flex: 1;
			border-radius: 10px;
			height: 49px;
		}
	}
	.remark {
		text-align: left;
		margin-top: 10px;
		font-size: 12px;
		color: #999999;
		span {
			color: #017eff;
		}
	}

	.input_box {
		margin-bottom: 20px;
		.lable {
			font-family: PingFang-SC-Medium;
			font-size: 12px;
			color: #000000;
			text-align: left;
			margin-bottom: 4px;
		}
		.input {
			border-radius: 10px;
			border: solid 1px #cccccc;
			height: 56px;
			padding: 0 16px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.van-field {
				flex: 1;
				padding: 0 15px 0 0;
			}
		}
	}

	.order_info {
		line-height: 28px;
		> div {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 13px;
			color: #333;
		}
		.gray {
			color: #999;
		}
		.line {
			height: 1px;
			background-color: #e5ecff;
			margin: 10px 0;
		}
	}
}
.no_keyboard {
	padding-bottom: 40px;
}

.orders_box {
	margin-top: 10px;
	background: #fff;
	border-radius: 10px;
	min-height: calc(100vh - 210px);
	.order {
		// border-top: 1px solid #e5ecff;
		margin: 5px 10px;
		padding: 10px 0;
		background: #f7faff;
		border-radius: 10px;
		padding: 10px;
		.title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 14px;
			color: #000;
			.status {
				font-size: 12px;
				color: #999;
			}
		}
		.price_time {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 5px;
			font-size: 12px;
			color: #999;
			.price {
				color: #000;
			}
		}
		.amount {
			margin-top: 10px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 12px;
			color: #000;
			> div > div:first-child {
				font-size: 12px;
				color: #999;
			}
		}
	}
}

.none_box {
	height: 500px;
	position: relative;
}

.order_view_title{
	margin-top: 20px;
	font-size: 14px;
	color: #333;
	text-align: center;
	line-height: 28px;
}
.order_view {
	-webkit-text-size-adjust: 100%; /* 100% 禁用字体大小调整 */
    text-size-adjust: 100%;
	background: #f7faff;
	border-radius: 8px;
	padding: 10px;
	margin-top: 10px;
	.title {
		font-size: 13px;
		text-align: center;
		color: #333;
		line-height: 24px;
	}
	.exchange_log {
		text-align: left;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		font-size: 12px;
		color: #000;
		gap: 6px 0;

		&:not(:first-child) {
			margin-top: 10px;
		}
		&:not(:last-child) {
			padding-bottom: 10px;
			border-bottom: #eee 1px solid;
		}
		> div > div:first-child {
			font-size: 12px;
			color: #999;
			margin-bottom: 2px;
		}

		>div{
			width: 50%;
		}
	}
	

	
	.thead{
		margin-top: 10px;
		font-size: 12px;
		color: #999;
		text-align: left;
		>div:last-child{
			text-align: right;
		}
	}

	.tr{
		padding: 4px 0;
		text-align: left;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 12px;
		color: #000;
		>div:last-child{
			text-align: right;
		}
	}
	
}

.status0{
	color: #FF5722;
}
.status1{
	color: #4CAF50;
}
.unlock_time{
	letter-spacing: -0.5px;
}
</style>
