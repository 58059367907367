<template>
	<div class="header">
		<img src="../assets/logo.png" class="logo" />
		<div class="wallet" @click="logout">
			<span>{{ $utils.handleAddress(userAccount, 8, 6) }}</span>
		</div>
		<div class="lang" @click="setLanguage">
			<img src="../assets/language.png" class="language_icon" />
			<span>{{ columns[language_index].name }}</span>
		</div>

		<van-popup v-model="showLanguage" round position="bottom">
			<van-picker show-toolbar :columns="columns" value-key="text" @cancel="showLanguage = false" @confirm="onConfirm" :default-index="language_index" :cancel-button-text="$t('head.t5')" :confirm-button-text="$t('head.t6')" />
		</van-popup>
	</div>
</template>

<script>
//加载img
export default {
	name: "HelloWorld",
	props: {},
	data() {
		return {
			superior: "",
			userAccount: "", //用户账户
			language: "zh-hant", //语言
			language_name: "繁體中文",
			language_index: 0,
			show_logout: false,
			showLanguage: false,
			columns: [
				{ key: "zh-hant", text: "繁體中文", name: "繁體" },
				// { key: "zh", text: "简体中文", name: "简体" },
				{ key: "en", text: "English", name: "EN" },
				{ key: "ja", text: "日本語", name: "日語" },
			],
		};
	},
	async mounted() {
		//获取语言设置
		console.log(localStorage.getItem("language"));
		this.language = localStorage.getItem("language") || "zh-hant";
		this.language_name = localStorage.getItem("language_name") || "繁體";
		this.language_index = localStorage.getItem("language_index") || 0;
		// 切换语言
		this.superior = this.$store.state.superior;
		this.userAccount = localStorage.getItem("userAccount");
		this.$i18n.locale = this.language;
		this.$store.state.language = this.language;
	},
	filters: {
		address(value) {
			if (!value) return "";
			return value.slice(0, 10) + "..." + value.slice(-6);
		},
	},
	watch: {
		//监听 this.$store.state.superior
		"$store.state.changeSuperior": function (newVal, oldVal) {
			console.log("newVal:", newVal, "oldVal:", oldVal);
			this.superior = this.$store.state.superior;
		},
	},
	methods: {
		//切换语言
		setLanguage() {
			this.showLanguage = true;
		},
		onConfirm(value, index) {
			// console.log(value,index);
			this.showLanguage = false;
			this.language_index = index;
			this.language = value.key;
			this.language_name = value.name;
			// 切换语言
			this.$i18n.locale = this.language;
			this.$store.state.language = value.key;
			this.$store.state.changeNum += 1;
			// 保存语言设置
			localStorage.setItem("language_index", this.language_index);
			localStorage.setItem("language", this.language);
			localStorage.setItem("language_name", value.name);
		},

		//断开链接
		logout() {
			if (!this.userAccount) return;
			//询问
			this.$dialog
				.confirm({
					title: "提示",
					message: "是否断开连接？",
				})
				.then(() => {
					localStorage.clear();
					localStorage.setItem('stopAutoLogin', 1)
					window.location.reload();
				})
				.catch(() => {
					console.log("取消");
				});
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
	background: #007efe;
	display: flex;
	justify-content: space-between;
	position: relative;
	padding: 0 15px;
	height: 50px;
	align-items: center;

	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 999;
	max-width: calc(500px - 24px);
	margin: auto;
	.left,
	.right {
		display: flex;
		align-items: center;
	}
	.logo {
		width: 30px;
	}
	.language_icon {
		height: 20px;
	}

	.wallet {
		display: flex;
		align-items: center;
		border-radius: 14px;
		padding: 0 10px;
		height: 27px;
		border: solid 1px #ffffff;
		// margin-left: 10px;
		position: absolute;
		left: 0;
		right: 0;
		width: fit-content;
		margin: auto;
		span {
			font-size: 13px;
			color: #fff;
		}
	}
	.lang {
		display: flex;
		align-items: center;
		gap: 5px;
		font-size: 14px;
		color: #fff;
	}
	.language {
		width: 66px;
		height: 24px;
		background: #ffffff;
		border: 1px solid #089553;
		border-radius: 12px;
		display: flex;
		align-items: center;
		padding: 0 3px;
		img {
			width: 20px;
			border-radius: 50%;
		}
		span {
			margin: 0 auto;
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #089553;
		}
	}
	.dialog_box {
		padding: 0 15px;
	}
}

.content_box {
	margin-top: 12px !important;
}
.btn_box {
	padding: 20px 0 30px !important;
	display: flex;
	gap: 10px;
	> button {
		width: 100%;
	}
}
</style>
