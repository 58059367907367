import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { connectWallet } from '@/api/connectWallet.js';
Vue.prototype.$connectWallet = connectWallet;

import MyContract from '@/api/MyContract.js';
Vue.prototype.$MyContract = MyContract;

// axios
import axios from 'axios'
Vue.prototype.$axios = axios

//copy
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

//i18n
import i18n from './utils/i18n.js';

//vant
import Vant from './utils/vant.js';
import 'vant/lib/index.css'; // 引入 Vant 样式文件
import './styles/variables.scss'; // 引入自定义样式文件
Vue.use(Vant);

//全局引入utils.js
import * as utils from './utils/utils';
Vue.prototype.$utils = utils;

Vue.config.productionTip = false

import { fetchData, postData, request,uploadData } from './api/api';
Vue.prototype.$api = {
  fetchData,
  postData,
  request,
  uploadData
};

if (process.env.NODE_ENV === 'production') {
  window.addEventListener('unhandledrejection', event => {
      event.preventDefault(); // 阻止生产环境弹窗
      console.log('错误捕获：', event.reason);
  });
}

new Vue({
	router,
	store,
  i18n,
	render: h => h(App)
}).$mount('#app')
