import { Notify } from 'vant-green';
import { postData } from '../api/api';
// web3
import Web3 from 'web3';
import i18n from '../utils/i18n.js';
var networkConfig = null;
var ethereum = null;

if (window.ethereum) {
    ethereum = window.ethereum;
} else if (window.web3) {
    ethereum = window.web3.currentProvider;
} else {
    Notify(
        {
            type: 'danger',
            message: i18n.t("methods.t0"),
            duration: 10000
        }
    );
    throw new Error(i18n.t("methods.t0"));
}

// 监听账户变化事件
ethereum.on('accountsChanged', (accounts) => {
    console.log("accountsChanged", accounts);
    localStorage.removeItem("userAccount");
    localStorage.removeItem("token");
    window.location.reload();
});

// 监听网络变化事件
ethereum.on('chainChanged', (chainId) => {
    console.log("chainChanged", chainId);
    localStorage.removeItem("userAccount");
    localStorage.removeItem("token");
    window.location.reload();
});


// 连接钱包并检查网络
export async function connectWallet(config) {
    try {
        if(ethereum == null){
            return false;
        }else{
            networkConfig = {
                chainId: this.$utils.int2hex(config.chainId, 1), // 网络链 ID，必须是十六进制
                chainName: config.chainName, // 网络名称
                nativeCurrency: {
                    name: config.chainToken, // 本地代币名称
                    symbol: config.chainToken, // 本地代币符号
                    decimals: 18, // 本地代币小数点位数
                },
                rpcUrls: config.chainRPC, // RPC 节点 URL 列表
                blockExplorerUrls: config.blockExplorerUrls, // 区块浏览器 URL 列表
            };
            //检查网络是否存在，不存在则添加
            await checkNetwork(config);
            const account = await getWalletAddress();
            console.log('account', account);
            const sign = await signMessage(account, account);
            const res = await postData("login", {
                account: account,
                message: account,
                signature: sign
            });
            if (res.code == 200) {
                localStorage.setItem("signature_"+account, sign);
                localStorage.setItem("userAccount", account);

                localStorage.setItem("token", res.data);
                localStorage.setItem("user_id", res.user_id);
                this.$store.state.token = res.data;
                this.$store.state.user_id = res.user_id;

                this.$store.state.changLoign++;
                return account;
            } else {
                throw new Error(res.msg);
            }
        }
    } catch (Error) {
        console.log(Error); 
        throw Error;
    }
}

// 获取用户钱包地址
async function getWalletAddress() {
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    return accounts[0];
}

function utf8ToHex(str) {
    return Web3.utils.utf8ToHex(str); // 推荐直接使用 web3.js 内置方法
}
// 重写拉起签名消息
async function signMessage(address, message) {
    return await ethereum.request({ method: 'personal_sign', params: [utf8ToHex(message), address, ""] });
}
// 检查并切换网络
async function checkNetwork(config) {
    const chainId = await ethereum.request({method: 'eth_chainId'})
    if (chainId !== networkConfig.chainId) {
        try {
            await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: networkConfig.chainId }],
            })
        } catch (error) {
            console.error('切换网络失败:', error);
            // alert(error.code);
            if (error.code === 4902 || error.code === -32603 || error.code === 4001) {
                // 网络未添加到 MetaMask，尝试添加网络
                localStorage.removeItem("userAccount");
                await addNetwork();
                await connectWallet(config);
            } else {
                throw error;
            }
        }
    }
}

// 添加网络到 MetaMask
async function addNetwork() {
    try {
        await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [networkConfig],
        });
    } catch (error) {
        console.error('添加网络失败:', error);
        throw error;
    }
}


