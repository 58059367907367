import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
	"zh": {
		api: {
			t1: "登录已过期，请重新登录"
		},
		methods: {
			t0: "未检测到钱包，请刷新页面后再试！",
			t1: "余额检查中...",
			t2: "代币余额不足",
			t3: '余额充足，检查授权',
			t4: '代币授权中...',
			t5: '授权成功',
			t6: '授权失败',
			t7: "执行合约中...",
			t8: '订单不存在',
			t9: '不能购买自己的商品',
			t10: '余额检查中...',
			t11: '代币余额不足',
			t12: '验证中',
			t13: '订单已取消',
			t14: '只能撤销自己的订单',
			t15: '执行合约',
			t16: '用户拒绝了交易',
			t17: '调用合约时发生错误',
			t18: '账户余额不足，无法完成交易',
			t19: '授权额度不足，请增加授权额度',
			t20: '提供的交易金额不足，无法完成交易',
			t21: '无法预计Gas消化，合约执行可能会出错',
			t22: '无法估算 Gas 限制，请手动设置 gas limit',
			t23: '网络连接异常，请检查您的网络连接',
			t24: '请求超时，请稍后重试',
			t25: '交易已过期，请重试',
			t26: '交易的 gas 价格过低，请提高 gas 价格',
			t27: '交易已被替换',
			t28: '交易耗尽 Gas，请增加 Gas 限制',
			t29: '签名无效，请检查签名参数',
			t30: '交易失败'
		},
		head: {
			t1: "链接钱包",
			t2: "钱包地址复制成功",
			t3: "断开链接",
			t4: "是否断开钱包链接？",
			t5: "取消",
			t6: "确认",
		},
		recharge: {
			t1: "我的账户",
			t2: "单次最低充值：",
			t3: "金额",
			t4: "最大",
			t5: "余额：",
			t6: "取消",
			t7: "确认",
			t8: "提交中...",
			t9: "上级地址错误，请重新绑定",
			t10: "余额不足",
			t11: "额度授权中...",
			t12: "授权成功",
			t13: "合约执行中...",
			t14: "充值成功",
		},
		index: {
			t1: "下拉即可刷新...",
			t2: "释放即可刷新...",
			t3: "加载中...",
			t4: "我的账户",
			t5: "账户余额",
			t6: "代币余额",
			t7: "已解锁",
			t8: "质押转出",
			t9: "购买挂单",
			t10: "闪兑出售",
			t11: "充值提现",
			t12: "委托中",
			t13: "委托单价：",
			t14: "求购数量",
			t15: "已成交量",
			t16: "剩余数量",
			t17: "暂无数据~",
			t17_1: "已撤单",
			t17_2: "完全成交",
			t17_3: "历史委托",
			t18: "委托单价：",
			t19: "求购数量",
			t20: "已成交量",
			t21: "价值",
			t22: "未成交",
			t23: "暂无数据~",
			t24_1: "出售记录",
			t24: "闪兑出售",
			t25: "成交均价：",
			t26: "出售数量",
			t27: "收益",
			t28: "手续费",
			t29: "暂无数据~",
			t30: "转出到质押",
			t30_1: "质押数量",
			t31: "可质押数量：",
			t32: "全部",
			t33: "取消",
			t34: "转出到质押...",
			t35: "确认质押",
			t36: "转出到质押预计需要5分钟完成",
			t37: "转出成功后，如需查看或兑换算力请",
			t38: "前往PowerLp",
			t39: "PowerLp中兑换的算力包产生的收益会自动转移到您的账户中",
			t40: "求购单",
			t41: "求购数量",
			t42: "委托单价",
			t43: "支付金额",
			t44: "账户余额：",
			t45: "取消",
			t46: "下单中...",
			t47: "确认",
			t48: "您的求购订单将按照价格高低和时间先后进行排队成交",
			t49: "每购买成交1个Pow,24小时将为您解锁1.1个Pow可售金额",
			t50: "未成交部分可撤单，将收取剩余价值的3%手续费",
			t51: "订单详情",
			t52: "订单编号",
			t53: "订单状态",
			t54: "已撤单",
			t55: "委托中",
			t56: "完成交易",
			t57: "求购数量",
			t58: "委托价格",
			t59: "已成交量",
			t60: "委托价值",
			t61: "已成交价值",
			t62: "解锁可售",
			t62_1: "剩余价值",
			t63: "退还金额",
			t64: "撤单手续费",
			t65: "交易对手",
			t66: "成交",
			t67: "可解锁",
			t68: "解锁时间",
			t69: "解锁状态",
			t70: "待解锁",
			t71: "已解锁",
			t72: "成交时间",
			t73: "交易对手",
			t74: "您的求购订单将按照价格高低和时间先后进行排队成交",
			t75: "每购买成交1个Pow,24小时将为您解锁1.1个Pow可售金额",
			t76: "未成交部分可撤单，将收取剩余价值的3%手续费",
			t77: "关闭",
			t78: "撤单中...",
			t79: "撤单",
			t80: "订单详情",
			t81: "订单状态",
			t82: "闪兑成功",
			t83: "兑换时间",
			t84: "消耗",
			t85: "获得",
			t86: "成交均价",
			t87: "手续费",
			t88: "交易对手",
			t89: "消耗",
			t90: "单价",
			t91: "价值",
			t92: "交易对手",
			t93: "关闭",
			t94: "提示",
			t95: "是否退出登录？",
			t96: "复制成功",
			t97: "复制失败",
			t98: "请输入求购数量",
			t99: "请输入求购单价",
			t100: "网络错误",
			t101: "请输入转出数量",
			t102: "您的代币余额不足",
			t103: "单次买入限制500Pow",
			t104: "解锁额度不足",
			t105: "买入价格不能低于{min_sell_price} USDT",
			t106: "最低价：{min_sell_price} USDT",
		},
		exchange: {
			t1: "返回",
			t2: "闪兑",
			t3: "买单池：",
			t4: "消耗",
			t5: "可用：",
			t6: "最大",
			t7: "当前可售出数量不足，请先购买即可解锁",
			t8: "预计可得",
			t9: "成交均价：",
			t10: "预览兑换",
			t11: "订单表",
			t12: "最新成交",
			t13: "单价",
			t14: "数量",
			t15: "价值",
			t16: "暂无订单",
			t17: "单价",
			t18: "数量",
			t19: "时间",
			t20: "确认兑换",
			t21: "消耗",
			t22: "获得",
			t23: "您预计可得",
			t24: "已解锁可售",
			t25: "兑换均价",
			t26: "交易费",
			t27: "报价仅供参考，实际兑换金额可能会有所不同",
			t28: "交易手续费按照成交金额5%收取",
			t29: "关闭",
			t30: "兑换确认中...",
			t31: "确认兑换（{t}秒）",
			t32: "最小出售数量为{min}个Pow",
			t33: "请输入出售数量",
			t34: "解锁可售出数量不足",
			t35: "市场求购数量不足",
		},
		wallet: {
			t1: "账户管理",
			t2: "充值",
			t3: "提现",
			t4: "请输入充值金额",
			t5: "钱包余额：",
			t6: "全部",
			t7: "到账地址",
			t8: "合约执行中...",
			t9: "确认充值",
			t10: "请输入提现金额",
			t11: "可提现余额：",
			t12: "全部",
			t13: "到账地址",
			t14: "提现申请中...",
			t15: "确认提现",
			t16: "每笔提现手续费1%，最低收取0.3 USDT",
			t17: "单次提现最低1 USDT起，最高不设上限",
			t18: "提现到账预计时间:0-3个工作日",
			t19: "充值记录",
			t20: "暂无数据",
			t21: "用户充值",
			t22: "提现记录",
			t23: "暂无数据",
			t24: "审核中",
			t25: "执行中",
			t26: "已拒绝",
			t27: "已完成",
			t28: "审核中，请耐心等待",
			t29: "审核通过，等待链上执行",
			t30: "拒绝提现：",
			t31: "查看拒绝原因",
			t32: "仅显示最近100条记录",
			t33: "数据加载中...",
			t34: "请输入充值金额",
			t35: "USDT余额不足",
			t36: "授权失败，请检查USDT余额和合约余额",
			t37: "充值成功",
			t38: "充值失败，请检查USDT余额和合约余额",
			t39: "请输入提现金额",
			t40: "可提现余额不足",
			t41: "用户充值",
			t42: "用户提现",
			t43: "审核中",
			t44: "提现中",
			t45: "拒绝提现",
			t46: "拒绝理由",
			t47: "未填写拒绝理由",
			t48: "知道了"
		},
		log: {
			t1: "流水明细",
			t2: "没有更多了",
			t3: "暂无数据",
			t4: "网络错误",
		},
		action: {
			t1: "求购单-支付",
			t2: "撤单退款",
			t3: "撤单手续费",
			t4: "闪兑收益",
			t5: "成交POW",
			t6: "质押POW",
			t7: "闪兑交易手续费",
			t8: "质押收益",
			t9: "下级首单解锁",
			t14: "充值USDT",
			t15: "提现USDT",
			t16: "拒绝提现",
			t17: "提现手续费",
			t18: "提现退回",
			t19: "批量提现多笔手续费",
		},
	},
	"zh-hant": {
		api: {
			t1: "登錄已過期，請重新登錄"
		},
		methods: {
			t0: "未檢測到錢包，請刷新頁面後再試！",
			t1: "餘額檢查中...",
			t2: "代幣餘額不足",
			t3: "餘額充足，檢查授權",
			t4: "代幣授權中...",
			t5: "授權成功",
			t6: "授權失敗",
			t7: "執行合約中...",
			t8: "訂單不存在",
			t9: "不能購買自己的商品",
			t10: "餘額檢查中...",
			t11: "代幣餘額不足",
			t12: "驗證中",
			t13: "訂單已取消",
			t14: "只能撤銷自己的訂單",
			t15: "執行合約",
			t16: "用戶拒絕了交易",
			t17: "調用合約時發生錯誤",
			t18: "帳戶餘額不足，無法完成交易",
			t19: "授權額度不足，請增加授權額度",
			t20: "提供的交易金額不足，無法完成交易",
			t21: "無法預計 Gas 消耗，合約執行可能會出錯",
			t22: "無法估算 Gas 限制，請手動設置 Gas 限制",
			t23: "網絡連接異常，請檢查您的網絡連接",
			t24: "請求超時，請稍後重試",
			t25: "交易已過期，請重試",
			t26: "交易的 Gas 價格過低，請提高 Gas 價格",
			t27: "交易已被替換",
			t28: "交易耗盡 Gas，請增加 Gas 限制",
			t29: "簽名無效，請檢查簽名參數",
			t30: "交易失敗"
		},
		head: {
			t1: "連接錢包",
			t2: "錢包地址複製成功",
			t3: "斷開連接",
			t4: "是否斷開錢包連接？",
			t5: "取消",
			t6: "確認",
		},
		recharge: {
			t1: "我的賬戶",
			t2: "單次最低充值：",
			t3: "金額",
			t4: "最大",
			t5: "餘額：",
			t6: "取消",
			t7: "確認",
			t8: "提交中...",
			t9: "上級地址錯誤，請重新綁定",
			t10: "餘額不足",
			t11: "額度授權中...",
			t12: "授權成功",
			t13: "合約執行中...",
			t14: "充值成功",
		},
		index: {
			t1: "下拉即可刷新...",
			t2: "釋放即可刷新...",
			t3: "加載中...",
			t4: "我的賬戶",
			t5: "賬戶餘額",
			t6: "代幣餘額",
			t7: "已解鎖",
			t8: "質押轉出",
			t9: "購買掛單",
			t10: "閃兌出售",
			t11: "充值提現",
			t12: "委託中",
			t13: "委託單價：",
			t14: "求購數量",
			t15: "已成交量",
			t16: "剩餘數量",
			t17: "暫無數據~",
			t17_1: "已撤單",
			t17_2: "完全成交",
			t17_3: "歷史委託",
			t18: "委託單價：",
			t19: "求購數量",
			t20: "已成交量",
			t21: "價值",
			t22: "未成交",
			t23: "暫無數據~",
			t24: "閃兌出售",
			t24_1: "出售單",
			t25: "成交均價：",
			t26: "出售數量",
			t27: "收益",
			t28: "手續費",
			t29: "暫無數據~",
			t30: "轉出到質押",
			t30_1: "質押數量",
			t31: "可質押數量：",
			t32: "全部",
			t33: "取消",
			t34: "轉出到質押...",
			t35: "確認質押",
			t36: "轉出到質押預計需要5分鐘完成",
			t37: "轉出成功後，如需查看或兌換算力請",
			t38: "前往PowerLp",
			t39: "PowerLp中兌換的算力包產生的收益會自動轉移到您的賬戶中",
			t40: "求購單",
			t41: "求購數量",
			t42: "委託單價",
			t43: "支付金額",
			t44: "賬戶餘額：",
			t45: "取消",
			t46: "下單中...",
			t47: "確認",
			t48: "您的求購訂單將按照價格高低和時間先後進行排隊成交",
			t49: "每購買成交1個Pow,24小時將為您解鎖1.1個Pow可售金額",
			t50: "未成交部分可撤單，將收取剩餘價值的3%手續費",
			t51: "訂單詳情",
			t52: "訂單編號",
			t53: "訂單狀態",
			t54: "已撤單",
			t55: "委託中",
			t56: "完成交易",
			t57: "求購數量",
			t58: "委託價格",
			t59: "已成交量",
			t60: "委託價值",
			t61: "已成交價值",
			t62: "解鎖可售",
			t62_1: "剩餘價值",
			t63: "退還金額",
			t64: "撤單手續費",
			t65: "交易對手",
			t66: "成交",
			t67: "可解鎖",
			t68: "解鎖時間",
			t69: "解鎖狀態",
			t70: "待解鎖",
			t71: "已解鎖",
			t72: "成交時間",
			t73: "交易對手",
			t74: "您的求購訂單將按照價格高低和時間先後進行排隊成交",
			t75: "每購買成交1個Pow,24小時將為您解鎖1.1個Pow可售金額",
			t76: "未成交部分可撤單，將收取剩餘價值的3%手續費",
			t77: "關閉",
			t78: "撤單中...",
			t79: "撤單",
			t80: "訂單詳情",
			t81: "訂單狀態",
			t82: "閃兌成功",
			t83: "兌換時間",
			t84: "消耗",
			t85: "獲得",
			t86: "成交均價",
			t87: "手續費",
			t88: "交易對手",
			t89: "消耗",
			t90: "單價",
			t91: "價值",
			t92: "交易對手",
			t93: "關閉",
			t94: "提示",
			t95: "是否退出登錄？",
			t96: "複製成功",
			t97: "複製失敗",
			t98: "請輸入求購數量",
			t99: "請輸入求購單價",
			t100: "網絡錯誤",
			t101: "請輸入轉出數量",
			t102: "您的代幣餘額不足",
			t103: "單次買入限制500Pow",
			t104: "解鎖額度不足",
			t105: "買入價格不能低於{min_sell_price} USDT",
			t106: "最低價：{min_sell_price} USDT",
		},
		exchange: {
			t1: "返回",
			t2: "閃兌",
			t3: "買單池：",
			t4: "消耗",
			t5: "可用：",
			t6: "最大",
			t7: "當前可售出數量不足，請先購買即可解鎖",
			t8: "預計可得",
			t9: "成交均價：",
			t10: "預覽兌換",
			t11: "訂單表",
			t12: "最新成交",
			t13: "單價",
			t14: "數量",
			t15: "價值",
			t16: "暫無訂單",
			t17: "單價",
			t18: "數量",
			t19: "時間",
			t20: "確認兌換",
			t21: "消耗",
			t22: "獲得",
			t23: "您預計可得",
			t24: "已解鎖可售",
			t25: "兌換均價",
			t26: "交易費",
			t27: "報價僅供參考，實際兌換金額可能會有所不同",
			t28: "交易手續費按照成交金額5%收取",
			t29: "關閉",
			t30: "兌換確認中...",
			t31: "確認兌換（{t}秒）",
			t32: "最小出售數量為{min}個Pow",
			t33: "請輸入出售數量",
			t34: "解鎖可售出數量不足",
			t35: "市場求購數量不足",
		},
		wallet: {
			t1: "賬戶管理",
			t2: "充值",
			t3: "提現",
			t4: "請輸入充值金額",
			t5: "錢包餘額：",
			t6: "全部",
			t7: "到賬地址",
			t8: "合約執行中...",
			t9: "確認充值",
			t10: "請輸入提現金額",
			t11: "可提現餘額：",
			t12: "全部",
			t13: "到賬地址",
			t14: "提現申請中...",
			t15: "確認提現",
			t16: "每筆提現手續費1%，最低收取0.3 USDT",
			t17: "單次提現最低1 USDT起，最高不設上限",
			t18: "提現到賬預計時間:0-3個工作日",
			t19: "充值記錄",
			t20: "暫無數據",
			t21: "用戶充值",
			t22: "提現記錄",
			t23: "暫無數據",
			t24: "審核中",
			t25: "執行中",
			t26: "已拒絕",
			t27: "已完成",
			t28: "審核中，請耐心等待",
			t29: "審核通過，等待鏈上執行",
			t30: "拒絕提現：",
			t31: "查看拒絕原因",
			t32: "僅顯示最近100條記錄",
			t33: "數據加載中...",
			t34: "請輸入充值金額",
			t35: "USDT餘額不足",
			t36: "授權失敗，請檢查USDT餘額和合約餘額",
			t37: "充值成功",
			t38: "充值失敗，請檢查USDT餘額和合約餘額",
			t39: "請輸入提現金額",
			t40: "可提現餘額不足",
			t41: "用戶充值",
			t42: "用戶提現",
			t43: "審核中",
			t44: "提現中",
			t45: "拒絕提現",
			t46: "拒絕理由",
			t47: "未填寫拒絕理由",
			t48: "知道了",
		},
		log: {
			t1: "流水明細",
			t2: "沒有更多了",
			t3: "暫無數據",
			t4: "網絡錯誤",
		},
		action: {
			t1: "求購單-支付",
			t2: "撤單退款",
			t3: "撤單手續費",
			t4: "閃兌收益",
			t5: "成交POW",
			t6: "質押POW",
			t7: "閃兌交易手續費",
			t8: "質押收益",
			t9: "下級首單解鎖",
			t14: "充值USDT",
			t15: "提現USDT",
			t16: "拒絕提現",
			t17: "提現手續費",
			t18: "提現退回",
			t19: "批量提現多筆手續費",
		},
	},
	en: {
		api: {
			t1: "Login has expired, please log in again"
		},
		methods: {
			t0: "Wallet not detected, please refresh the page and try again!",
			t1: "Checking balance...",
			t2: "Insufficient token balance",
			t3: "Balance is sufficient, checking authorization",
			t4: "Authorizing token...",
			t5: "Authorization successful",
			t6: "Authorization failed",
			t7: "Executing contract...",
			t8: "Order does not exist",
			t9: "Cannot purchase your own product",
			t10: "Checking balance...",
			t11: "Insufficient token balance",
			t12: "Verifying",
			t13: "Order has been canceled",
			t14: "Can only cancel your own orders",
			t15: "Execute contract",
			t16: "User rejected the transaction",
			t17: "An error occurred while invoking the contract",
			t18: "Insufficient account balance to complete the transaction",
			t19: "Authorization limit is insufficient, please increase the authorization limit",
			t20: "Provided transaction amount is insufficient to complete the transaction",
			t21: "Unable to estimate gas consumption, contract execution may fail",
			t22: "Cannot estimate gas limit, please set the gas limit manually",
			t23: "Network connection error, please check your internet connection",
			t24: "Request timed out, please try again later",
			t25: "Transaction has expired, please try again",
			t26: "Gas price for the transaction is too low, please increase the gas price",
			t27: "Transaction has been replaced",
			t28: "Transaction ran out of gas, please increase the gas limit",
			t29: "Invalid signature, please check the signature parameters",
			t30: "Transaction failed"
		},
		head: {
			t1: "Connect Wallet",
			t2: "Wallet address copied successfully",
			t3: "Disconnect",
			t4: "Do you want to disconnect the wallet?",
			t5: "Cancel",
			t6: "Confirm",
		},
		recharge: {
			t1: "My Account",
			t2: "Minimum single recharge:",
			t3: "Amount",
			t4: "Maximum",
			t5: "Balance:",
			t6: "Cancel",
			t7: "Confirm",
			t8: "Submitting...",
			t9: "Invalid upstream address, please rebind",
			t10: "Insufficient balance",
			t11: "Authorizing limit...",
			t12: "Authorization successful",
			t13: "Executing contract...",
			t14: "Recharge successful",
		},
		index: {
			t1: "Pull down to refresh...",
			t2: "Release to refresh...",
			t3: "Loading...",
			t4: "My Account",
			t5: "Account Balance",
			t6: "Token Balance",
			t7: "Unlocked",
			t8: "Pledge",
			t9: "Buy",
			t10: "Sale",
			t11: "R & W",
			t12: "In Delegation",
			t13: "Delegation Price:",
			t14: "Purchase Quantity",
			t15: "Volume Traded",
			t16: "Remaining Quantity",
			t17: "No Data~",
			t17_1: "Order Canceled",
			t17_2: "Fully Completed",
			t17_3: "Order History",
			t18: "Delegation Price:",
			t19: "Purchase Quantity",
			t20: "Volume Traded",
			t21: "Value",
			t22: "Untraded",
			t23: "No Data~",
			t24: "Quick Sale",
			t24_1: "Sales Record",
			t25: "Avg Price:",
			t26: "Amount",
			t27: "Revenue",
			t28: "Fee",
			t29: "No Data~",
			t30: "Transfer Out to Stake",
			t30_1: "Stake Quantity",
			t31: "Stakable Quantity:",
			t32: "All",
			t33: "Cancel",
			t34: "Transferring Out to Stake...",
			t35: "Confirm Stake",
			t36: "Transfer to stake is expected to take 5 minutes to complete",
			t37: "After successful transfer, to check or exchange computing power please",
			t38: "Go to PowerLp",
			t39: "Revenue generated from computing power packages exchanged in PowerLp will be automatically transferred to your account",
			t40: "Purchase Order",
			t41: "Purchase Quantity",
			t42: "Delegation Price",
			t43: "Payment Amount",
			t44: "Account Balance:",
			t45: "Cancel",
			t46: "Placing Order...",
			t47: "Confirm",
			t48: "Your purchase order will be queued for transaction based on price and time priority",
			t49: "For every 1 Pow purchased and completed, 1.1 Pow of unlockable saleable amount will be unlocked within 24 hours",
			t50: "The unexecuted portion can be cancelled, and a 3% handling fee will be charged on the remaining value",
			t51: "Order Details",
			t52: "Order Number",
			t53: "Order Status",
			t54: "Order Canceled",
			t55: "In Delegation",
			t56: "Completed Transaction",
			t57: "Purchase Quantity",
			t58: "Delegation Price",
			t59: "Volume Traded",
			t60: "Delegation Value",
			t61: "Traded Value",
			t62: "Unlockable Sale",
			t62_1: "surplus value",
			t63: "Refund Amount",
			t64: "Cancellation Fee",
			t65: "Counterparty",
			t66: "Transaction",
			t67: "Unlockable",
			t68: "Unlock Time",
			t69: "Unlock Status",
			t70: "Pending Unlock",
			t71: "Unlocked",
			t72: "Transaction Time",
			t73: "Counterparty",
			t74: "Your purchase order will be queued for transaction based on price and time priority",
			t75: "For every 1 Pow purchased and completed, 1.1 Pow of unlockable saleable amount will be unlocked within 24 hours",
			t76: "The unexecuted portion can be cancelled, and a 3% handling fee will be charged on the remaining value",
			t77: "Close",
			t78: "Canceling Order...",
			t79: "Cancel Order",
			t80: "Order Details",
			t81: "Order Status",
			t82: "Quick Sale Successful",
			t83: "Exchange Time",
			t84: "Spent",
			t85: "Received",
			t86: "Average Transaction Price",
			t87: "Transaction Fee",
			t88: "Counterparty",
			t89: "Spent",
			t90: "Unit Price",
			t91: "Value",
			t92: "Counterparty",
			t93: "Close",
			t94: "Notice",
			t95: "Do you want to log out?",
			t96: "Copy Successful",
			t97: "Copy Failed",
			t98: "Please enter the purchase quantity",
			t99: "Please enter the delegation price",
			t100: "Network Error",
			t101: "Please enter the transfer quantity",
			t102: "Insufficient token balance",
			t103: "Single purchase limit 500Pow",
			t104: "Insufficient unlocking limit",
			t105: "The buying price cannot be lower than {min_sell_price} USDT",
			t106: "Minimum price: {min_sell_price} USDT",
		},
		exchange: {
			t1: "Back",
			t2: "Quick Exchange",
			t3: "Buy Order Pool:",
			t4: "Consume",
			t5: "Available:",
			t6: "Maximum",
			t7: "Current available quantity is insufficient, please purchase first to unlock",
			t8: "Expected Receive",
			t9: "Average Transaction Price:",
			t10: "Preview Exchange",
			t11: "Order Table",
			t12: "Latest Transactions",
			t13: "Unit Price",
			t14: "Quantity",
			t15: "Value",
			t16: "No Orders",
			t17: "Unit Price",
			t18: "Quantity",
			t19: "Time",
			t20: "Confirm Exchange",
			t21: "Consume",
			t22: "Receive",
			t23: "You are expected to receive",
			t24: "Unlocked and Available for Sale",
			t25: "Exchange Average Price",
			t26: "Transaction Fee",
			t27: "The quoted price is for reference only, actual exchange amount may vary",
			t28: "A 5% transaction fee will be charged based on the transaction amount",
			t29: "Close",
			t30: "Exchange Confirmation in Progress...",
			t31: "Confirm Exchange ({t} seconds)",
			t32: "The minimum sale quantity is {min} Pow",
			t33: "Please enter the sale quantity",
			t34: "Insufficient unlocked sale quantity",
			t35: "Market demand is insufficient"
		},
		wallet: {
			t1: "Account Management",
			t2: "Recharge",
			t3: "Withdraw",
			t4: "Please enter the recharge amount",
			t5: "Wallet Balance:",
			t6: "All",
			t7: "Deposit Address",
			t8: "Contract Execution in Progress...",
			t9: "Confirm Recharge",
			t10: "Please enter the withdrawal amount",
			t11: "Withdrawable Balance:",
			t12: "All",
			t13: "Withdrawal Address",
			t14: "Withdrawal Request in Progress...",
			t15: "Confirm Withdrawal",
			t16: "A 1% withdrawal fee will be charged, with a minimum of 0.3 USDT",
			t17: "Single withdrawal amount starts from 1 USDT, with no upper limit",
			t18: "Expected withdrawal time: 0-3 working days",
			t19: "Recharge Record",
			t20: "No Data",
			t21: "User Recharge",
			t22: "Withdrawal Record",
			t23: "No Data",
			t24: "In Review",
			t25: "In Progress",
			t26: "Rejected",
			t27: "Completed",
			t28: "Under review, please be patient",
			t29: "Review passed, waiting for on-chain execution",
			t30: "Withdrawal Rejected:",
			t31: "View rejection reason",
			t32: "Display only the last 100 records",
			t33: "Data Loading...",
			t34: "Please enter the recharge amount",
			t35: "Insufficient USDT balance",
			t36: "Authorization failed, please check your USDT and contract balances",
			t37: "Recharge successful",
			t38: "Recharge failed, please check your USDT and contract balances",
			t39: "Please enter the withdrawal amount",
			t40: "Insufficient withdrawable balance",
			t41: "User Recharge",
			t42: "User Withdrawal",
			t43: "Under Review",
			t44: "In Withdrawal",
			t45: "Withdrawal Rejected",
			t46: "Rejection Reason",
			t47: "Rejection reason not provided",
			t48: "Got it"
		},
		log: {
			t1: "Transaction Details",
			t2: "No more records",
			t3: "No Data",
			t4: "Network Error"
		},
		action: {
			t1: "Purchase Order - Payment",
			t2: "Cancel Order Refund",
			t3: "Order Cancellation Fee",
			t4: "Quick Exchange Earnings",
			t5: "Completed POW",
			t6: "Staked POW",
			t7: "Quick Exchange Transaction Fee",
			t8: "Staking Earnings",
			t9: "subordinate unlock",
			t14: "Recharge USDT",
			t15: "Withdraw USDT",
			t16: "Reject Withdrawal",
			t17: "Withdrawal Fee",
			t18: "Withdrawal Reversal",
			t19: "Bulk Withdrawal Multiple Fees"
		}
	},
	ja: {
		api: {
			t1: "ログインが期限切れです。再度ログインしてください"
		},
		methods: {
			t0: "ウォレットが検出されません。ページをリフレッシュしてから再試行してください！",
			t1: "残高を確認しています...",
			t2: "トークンの残高が不足しています",
			t3: "残高は十分です、認可を確認中",
			t4: "トークンの認可中...",
			t5: "認可成功",
			t6: "認可失敗",
			t7: "契約を実行中...",
			t8: "注文が存在しません",
			t9: "自分の商品は購入できません",
			t10: "残高を確認しています...",
			t11: "トークンの残高が不足しています",
			t12: "検証中",
			t13: "注文がキャンセルされました",
			t14: "自分の注文のみキャンセルできます",
			t15: "契約を実行中",
			t16: "ユーザーが取引を拒否しました",
			t17: "契約呼び出し中にエラーが発生しました",
			t18: "アカウント残高が不足しており、取引を完了できません",
			t19: "認可限度が不足しています。限度を増やしてください",
			t20: "提供された取引金額が不足しており、取引を完了できません",
			t21: "Gas消化が予測できません。契約実行時にエラーが発生する可能性があります",
			t22: "Gas制限の予測ができません。手動でgas limitを設定してください",
			t23: "ネットワーク接続異常です。ネットワーク接続を確認してください",
			t24: "リクエストがタイムアウトしました。後で再試行してください",
			t25: "取引が期限切れです。再試行してください",
			t26: "取引のgas価格が低すぎます。gas価格を上げてください",
			t27: "取引が差し替えられました",
			t28: "取引がGasを使い果たしました。Gas制限を増やしてください",
			t29: "署名が無効です。署名パラメーターを確認してください",
			t30: "取引失敗"
		},
		head: {
			t1: "ウォレットを接続",
			t2: "ウォレットアドレスのコピー成功",
			t3: "接続解除",
			t4: "ウォレットの接続を解除しますか？",
			t5: "キャンセル",
			t6: "確認"
		},
		recharge: {
			t1: "マイアカウント",
			t2: "1回の最低入金額：",
			t3: "金額",
			t4: "最大",
			t5: "残高：",
			t6: "キャンセル",
			t7: "確認",
			t8: "送信中...",
			t9: "上位アドレスが間違っています。再度バインドしてください",
			t10: "残高不足",
			t11: "認可中...",
			t12: "認可成功",
			t13: "契約実行中...",
			t14: "入金成功"
		},
		index: {
			t1: "下にスワイプしてリフレッシュ...",
			t2: "放してリフレッシュ...",
			t3: "読み込み中...",
			t4: "マイアカウント",
			t5: "アカウント残高",
			t6: "トークン残高",
			t7: "解放済み",
			t8: "ステーキング転送",
			t9: "購入注文",
			t10: "クイック交換販売",
			t11: "入金・出金",
			t12: "委託中",
			t13: "委託単価：",
			t14: "購入数量",
			t15: "取引済み数量",
			t16: "残り数量",
			t17: "データがありません~",
			t17_1: "キャンセル済み",
			t17_2: "完全に取引成立",
			t17_3: "過去の委託",
			t18: "委託単価：",
			t19: "購入数量",
			t20: "取引済み数量",
			t21: "価値",
			t22: "未取引",
			t23: "データがありません~",
			t24: "クイック交換販売",
			t24_1: "販売実績",
			t25: "取引平均単価：",
			t26: "販売数量",
			t27: "利益",
			t28: "手数料",
			t29: "データがありません~",
			t30: "ステーキングに転送",
			t30_1: "ステーキング数量",
			t31: "ステーキング可能数量：",
			t32: "すべて",
			t33: "キャンセル",
			t34: "ステーキングに転送...",
			t35: "ステーキングを確認",
			t36: "ステーキング転送は約5分で完了予定です",
			t37: "転送成功後、算力を確認または交換する場合は",
			t38: "PowerLpに移動",
			t39: "PowerLpで交換した算力パッケージからの利益は自動的にアカウントに移動します",
			t40: "購入注文",
			t41: "購入数量",
			t42: "委託単価",
			t43: "支払い金額",
			t44: "アカウント残高：",
			t45: "キャンセル",
			t46: "注文中...",
			t47: "確認",
			t48: "購入注文は価格の高低と時間の順番で並べられます",
			t49: "1つのPowを購入するごとに24時間で1.1Powが解放されます",
			t50: "未成約分はキャンセル可能で、残存価値の3%の手数料がかかります",
			t51: "注文詳細",
			t52: "注文番号",
			t53: "注文状態",
			t54: "キャンセル済み",
			t55: "委託中",
			t56: "取引完了",
			t57: "購入数量",
			t58: "委託価格",
			t59: "取引済み数量",
			t60: "委託の価値",
			t61: "取引済み価値",
			t62: "解放可能",
			t62_1: "ざんりゅうかち",
			t63: "返金金額",
			t64: "キャンセル手数料",
			t65: "取引相手",
			t66: "取引完了",
			t67: "解放可能",
			t68: "解放時間",
			t69: "解放状態",
			t70: "待機中",
			t71: "解放済み",
			t72: "取引時間",
			t73: "取引相手",
			t74: "購入注文は価格の高低と時間の順番で並べられます",
			t75: "1つのPowを購入するごとに24時間で1.1Powが解放されます",
			t76: "未成約分はキャンセル可能で、残存価値の3%の手数料がかかります",
			t77: "閉じる",
			t78: "キャンセル中...",
			t79: "キャンセル",
			t80: "注文詳細",
			t81: "注文状態",
			t82: "クイック交換成功",
			t83: "交換時間",
			t84: "消費",
			t85: "取得",
			t86: "取引平均単価",
			t87: "手数料",
			t88: "取引相手",
			t89: "消費",
			t90: "単価",
			t91: "価値",
			t92: "取引相手",
			t93: "閉じる",
			t94: "ヒント",
			t95: "ログアウトしますか？",
			t96: "コピー成功",
			t97: "コピー失敗",
			t98: "購入数量を入力してください",
			t99: "購入単価を入力してください",
			t100: "ネットワークエラー",
			t101: "転送数量を入力してください",
			t102: "トークンの残高が不足しています",
			t103: "1回の購入制限500Pow",
			t104: "ロック解除限度が不足している",
			t105: "購入価格は{min_sell_price} USDTを下回ってはならない",
			t106: "最低価格: {min_sell_price} USDT",
		},
		exchange: {
			t1: "戻る",
			t2: "クイック交換",
			t3: "購入注文プール：",
			t4: "消費",
			t5: "利用可能：",
			t6: "最大",
			t7: "現在、販売可能な数量が不足しています。購入してから解放してください",
			t8: "予想受取",
			t9: "取引平均単価：",
			t10: "交換プレビュー",
			t11: "注文表",
			t12: "最新取引",
			t13: "単価",
			t14: "数量",
			t15: "価値",
			t16: "注文はありません",
			t17: "単価",
			t18: "数量",
			t19: "時間",
			t20: "交換確認",
			t21: "消費",
			t22: "取得",
			t23: "予想受取",
			t24: "解放された販売可能",
			t25: "交換平均単価",
			t26: "取引手数料",
			t27: "提示された価格は参考用です。実際の交換金額は変動する可能性があります",
			t28: "交換数量",
			t29: "交換確認",
			t30: "手数料",
			t31: "取引完了"
		},
		wallet: {
			t1: "アカウント管理",
			t2: "チャージ",
			t3: "引き出し",
			t4: "チャージ額を入力してください",
			t5: "ウォレット残高：",
			t6: "すべて",
			t7: "入金アドレス",
			t8: "契約実行中...",
			t9: "チャージ確認",
			t10: "引き出し額を入力してください",
			t11: "引き出し可能残高：",
			t12: "すべて",
			t13: "引き出しアドレス",
			t14: "引き出し申請中...",
			t15: "引き出し確認",
			t16: "引き出し手数料は1%、最低0.3 USDT",
			t17: "一回の引き出しは最低1 USDT、上限なし",
			t18: "引き出しの到着予定時間：0〜3営業日",
			t19: "チャージ履歴",
			t20: "データはありません",
			t21: "ユーザーのチャージ",
			t22: "引き出し履歴",
			t23: "データはありません",
			t24: "審査中",
			t25: "実行中",
			t26: "拒否された",
			t27: "完了",
			t28: "審査中、しばらくお待ちください",
			t29: "審査通過、チェーン上での実行待ち",
			t30: "引き出し拒否：",
			t31: "拒否理由を見る",
			t32: "最新の100件のみ表示",
			t33: "データ読み込み中...",
			t34: "チャージ額を入力してください",
			t35: "USDT残高不足",
			t36: "認証失敗、USDT残高と契約残高を確認してください",
			t37: "チャージ成功",
			t38: "チャージ失敗、USDT残高と契約残高を確認してください",
			t39: "引き出し額を入力してください",
			t40: "引き出し可能残高不足",
			t41: "ユーザーのチャージ",
			t42: "ユーザーの引き出し",
			t43: "審査中",
			t44: "引き出し中",
			t45: "引き出し拒否",
			t46: "拒否理由",
			t47: "拒否理由未記入",
			t48: "了解しました"
		},
		log: {
			t1: "取引明細",
			t2: "これ以上はありません",
			t3: "データがありません",
			t4: "ネットワークエラー"
		},
		action: {
			t1: "購入注文-支払い",
			t2: "注文キャンセルによる返金",
			t3: "注文キャンセル手数料",
			t4: "フラッシュ交換収益",
			t5: "POWの取引成立",
			t6: "POWのステーキング",
			t7: "フラッシュ交換手数料",
			t8: "ステーキング収益",
			t9: "部下の最初のシングルロック解除",
			t14: "USDTのチャージ",
			t15: "USDTの引き出し",
			t16: "引き出し拒否",
			t17: "引き出し手数料",
			t18: "引き出し返金",
			t19: "複数回引き出しの手数料"
		}
	}
};

const i18n = new VueI18n({
	locale: 'zh-hant', // 默认语言
	fallbackLocale: 'zh-hant', // 未找到翻译时的默认语言
	messages
});

export default i18n;
