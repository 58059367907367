import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '../views/IndexView.vue'
import ErrorView from '../views/ErrorView.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'index',
		component: IndexView,
		meta: { keepAlive: true }
	},
	{
		path: '/exchange',
		name: 'exchange',
		component: () => import('../views/ExchangeView.vue'),
		meta: { keepAlive: true }
	},
	
	{
		path: '/wallet',
		name: 'wallet',
		component: () => import('../views/WalletView.vue'),
		meta: { keepAlive: true }
	},
	{
		path: '/balance',
		name: 'balance',
		component: () => import('../views/BalanceLog.vue'),
		meta: { keepAlive: true }
	},
	{
		path: '/404',
		name: '404',
		component: ErrorView
	},
]

const router = new VueRouter({
	routes
})

export default router
