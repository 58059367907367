<template>
	<div class="container">
		<img src="../assets/404.png" alt="">
		<div>页面走丢了～</div>
	</div>
</template>

<script>
export default {
	name: "ErrorView"
};
</script>

<style scoped lang="scss">
.container {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	img {
		width: 300px;
	}
	div {
		margin-top: 20px;
		font-size: 18px;
		font-weight: bold;
	}
}

</style>