import axios from 'axios';
import { Notify } from 'vant-green';
import router from '@/router';
import i18n from '../utils/i18n.js';
// 是否是开发环境
const isDev = process.env.NODE_ENV === 'development';
console.log('是否是开发环境', isDev);
// const baseURL = isDev ? '/dev' : '/api';
const baseURL = '/api';

// 创建一个 axios 实例
const apiClient = axios.create({
    // baseURL: process.env.VUE_APP_API_BASE_URL || '/api', // 使用环境变量配置 API 基础 URL
    baseURL: baseURL, // 使用环境变量配置 API 基础 URL
    headers: {
        'Content-Type': 'application/json',
    },
});
// 添加请求拦截器
apiClient.interceptors.request.use(
    (config) => {
        config.headers.domain = window.location.hostname;
        config.headers.storeid = localStorage.getItem('store_id') || '';
        // 判断是否需要传递 token
        const token = localStorage.getItem('token'); // 示例：从 localStorage 中获取 token
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        // 处理请求错误
        return Promise.reject(error);
    }
);
// 添加响应拦截器,如果code==401,跳转到登录页面
apiClient.interceptors.response.use(
    (response) => {
        // 处理响应数据
        if (response.data && response.data.code === 401) {
            Notify({ type: 'danger', message:  i18n.t('api.t1')});  //登录已过期，请重新登录
            const inviteAddress = localStorage.getItem("inviteAddress")
            localStorage.clear();
            localStorage.setItem("inviteAddress", inviteAddress);
            window.location.reload();
        } else if (response.data && response.data.code === 408) {
            //跳转404页面
            router.push({ path: '/404' }).catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    throw err;
                }
            });
            return false;
        }
        return response;
    },
    (error) => {
        // 处理响应错误
        return Promise.reject(error);
    }
);


// 定义通用请求函数
export function request(func, data = {}, method = 'get') {
    method = method.toLowerCase();
    if (method === 'get') {
        return apiClient.get(func, { params: data }).then(response => response.data).catch(error => {
            console.log('Error fetching data:', error);
            throw error;
        });
    } else if (method === 'post') {
        return apiClient.post(func, data).then(response => response.data).catch(error => {
            console.log('Error posting data:', error);
            throw error;
        });
    }
}
// 定义接口请求函数
export function fetchData(func) {
    return apiClient.get(func)
        .then(response => response.data)
        .catch(error => {
            console.log('Error fetching data:', error);
            throw error;
        });
}

export function postData(func, data) {
    return apiClient.post(func, data).then(response => response.data).catch(error => {
        console.log('Error posting data:', error);
        throw error;
    });
}

// 上传多个文件的函数，附带额外的参数
export function uploadData(func, files, additionalParams) {
    // 创建一个 FormData 对象
    let formData = new FormData();

    // 将多个文件添加到 FormData 中
    files.forEach((file, index) => {
        formData.append(`files${index}`, file.file); // 使用数组格式上传多个文件
    });

    // 遍历附加参数并添加到 FormData 中
    for (const key in additionalParams) {
        if (Object.prototype.hasOwnProperty.call(additionalParams, key)) {
            formData.append(key, additionalParams[key]);
        }
    }

    return apiClient.post(func, formData, {
        headers: {
            'Content-Type': 'multipart/form-data' // 设置适当的 Content-Type
        }
    })
        .then(response => response.data)
        .catch(error => {
            console.error('Error uploading files:', error);
            throw error;
        });
}